@import 'reset.less';
@import 'global.less';
@import 'game.less';
@import 'popup.less';
@import 'setscore.less';

/*
    Responsiveness for
*/

@media only screen and (max-height: 450px) {
    #gamecontainer {
       display: none;
       transition: none;
    }
    #intro {
       display: none;
       transition: none;
    }
    #rotate {
       display: flex;
       transition: none;
    }
 }

 /*
    Disable all pointerevens to prevent zooming
 */

 .invisible {
    .button {
        pointer-events: none!important;;
    }
    a {
        pointer-events: none!important;

    }
    .sharebar .sharebutton {
        pointer-events: none!important;
    }

   }

 .pauseAnimation {
    animation-play-state: paused!important;
 }

