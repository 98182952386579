.popup.closed {
    max-height: 0;
    overflow: hidden;
}
.popup.open {
  bottom: 0;
  opacity: 1;
  height: 100vh;
}
.popup {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // background-image: url('/assets/background.jpg');
  background-size: cover;
  z-index: 10000;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;
  transition: opacity 0.6s ease-in;
  pointer-events: all;
  user-select: none;
}
.popup-content {
  position: fixed;
  opacity: 1;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 400px;;
  padding: 20px;
  color: black;
  transform: translateY(-120%);
  transition: transform 0.6s ease-in;
  max-height: calc(100% - 40px);
  pointer-events: all;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  overflow-y: auto;
  max-width: 550px;
  touch-action: pan-y;
}

.popup.open .popup-content {
  transform: translateY(0);
}

html.scrollblock {
   overflow-y: hidden;
}