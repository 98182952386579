html,
body
{
   font-family: monospace;
   font-size: 12px;
   color: #fff;
   display: block;
   touch-action: none;
   margin: 0;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
   select,
   textarea,
   input {
     font-size: 16px;
   }
 }
*,
*:before,
*:after
{
    box-sizing: border-box;
    user-select: none;
    pointer-events: none;
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    touch-action: none;
    text-size-adjust: none;
}

.hidden {
   display: none!important;
}

.invisible {
   opacity: 0!important;
   z-index: -1!important;
}

.fadeinout {
   transition: opacity 1.25s ease-in-out;
}


.button {
   pointer-events: all;
   cursor: pointer;   ;
   color: black;
   position: relative;
   margin: 10px 0;

   background: linear-gradient(180deg, #54CC39 0%, #8FDD7D 15.1%, #54CC39 99.99%);;
   border: 4px solid #FFFFFF;

   box-sizing: border-box;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;

   padding: 10px 20px;
   display: inline-flex;
   justify-content: center;
   align-items: center;

   &:hover {
      background: linear-gradient(180deg, #54CC39 0%, #8FDD7D 78.65%, #54CC39 99.99%);;
   }

   z-index: 2;
   min-width: 220px;
   min-height: 72px;
   font-size: 16px;

  font-family: Bungee Inline;
  font-style: normal;
  font-weight: normal;

  line-height: 19px;
  text-align: center;
   &.big {
     font-size: 24px;
     line-height: 24px;;
   }

   &.verybig {
      font-size: 40px;
      line-height: 48px;
   }

   &.bold {
      // height: 72px;
      // width: 209px;
      padding: 0;

      font-family: Bungee Inline;
      color: white;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
      font-weight: 300;

      transition:top 400ms 2500ms ease-out;
      position: relative;

      top: 0;
      img {
          width: 101px;
      }
   }

}



.overlay {
   position: absolute;
   min-width: 100vw;
   min-height: 100vh;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   justify-self: center;
   box-sizing: content-box;
   // background: linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);
   overflow-x: hidden;
   overflow-y: auto;
}

.overlay-content {
   /* border: 1px solid red; */
   width: 100%;
   max-width: 375px;
   min-height: 100vh;
   min-width: 320px;;

   position: relative;
   /* border: 1px solid red; */
   box-sizing: border-box;

   padding-top: 20px;
   display: flex;
   width: 100%;

   flex-flow: column nowrap;
   align-items: center;
   position: relative;
}

.default-infobox  {
      background-color: rgba(255,255,255,0.8);
      /* width: 500px; */
      color: black;
      font-family: Bungee Inline;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      /* border-radius: 50px; */
      z-index: 1;
      width:100%;

      @media only screen and (min-width: 425px) {
         width: 500px;
         border-radius: 25px;
      }
}


.imgvirus {
   // background-image: url('../assets/default/landing/covid19.png');
   background-size: cover;
   background-position: center;
   /* border: 1px solid red; */
   position: absolute;
   z-index: 0;

}

a {
   pointer-events: all;
   z-index: 2;
   cursor: pointer;
}

#creator {
   user-select: all;
   pointer-events: all!important;;
   position: fixed;
   width: 30vw;
   height: 30px;
   bottom: 0;
   right: 0;
   z-index: 10000;
   textarea {
      pointer-events: all;
      user-select: unset;
      width: 100%;
      height: calc(100vh - 100px);
   }
   .creatorform {
      display: none;
   }
   button {
      pointer-events: all;
   }
}

#creator.open {
   background-color: grey;
   width: 100vw;
   height: 100vh;
   .creatorform {
      display: block;
   }
}

#background {
   top:0;
   bottom:0;
   right:0;
   left:0;
   overflow: hidden;
   position: fixed;
   // background: linear-gradient(180deg, #FFB775 0%, #FF7A00 100%);
   // background-image: url('/assets/background.jpg');
   // background-size: cover;
   &.closed {
      .virus5 {
         top: 100vh;
      }
   }
   .virus5 {

      .imgvirus;
      position: fixed;
      left: -10px ;
      top: 300px;
      height: 100%;
      left: 0;
      right: 0;
      opacity: 20%;
      background-position: top;
      background-size: cover;
      // background-image: url('../assets/landing/virus.png');
      transition:top 800ms ease-out;
   }

}


.header, .bigheader, .medheader  {
   color: black;
   font-family: Bungee Inline;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 16px;
   &.green {
         color: #54CC39;
   }
   &.grey {
      color: #aaaaaa;
   }
   &.breakall {
      word-break: break-all;
   }
}

.grey {
   color: #aaaaaa;
}
.green {
   color: #54CC39;
}

.bigheader {
 font-size: 36px;
 line-height: 36px;
}

.medheader {
 font-size: 24px;
 line-height: 24px;
}

#btnReplay {
   position: fixed;
   bottom: 0;
   left: 0;
   pointer-events: all;
}