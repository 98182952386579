#setScoreScreen .header, #setScoreScreen .bigheader {
    margin-top: 16px;
  }

  #setScoreValue {
    color: green;

  }
  #setScoreValue.header {
    margin-top: 0;
    font-size: 24px;
  }