html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  vertical-align: baseline;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

ins {
  color: #000;
  background-color: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

input, select {
  vertical-align: middle;
}

html, body {
  color: #fff;
  touch-action: none;
  margin: 0;
  font-family: monospace;
  font-size: 12px;
  display: block;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  select, textarea, input {
    font-size: 16px;
  }
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: none;
  -webkit-tap-highlight-color: #0000;
  touch-action: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  outline: none;
}

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
  z-index: -1 !important;
}

.fadeinout {
  transition: opacity 1.25s ease-in-out;
}

.button {
  pointer-events: all;
  cursor: pointer;
  color: #000;
  box-sizing: border-box;
  z-index: 2;
  text-align: center;
  background: linear-gradient(#54cc39 0%, #8fdd7d 15.1%, #54cc39 99.99%);
  border: 4px solid #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  min-height: 72px;
  margin: 10px 0;
  padding: 10px 20px;
  font-family: Bungee Inline;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.button:hover {
  background: linear-gradient(#54cc39 0%, #8fdd7d 78.65%, #54cc39 99.99%);
}

.button.big {
  font-size: 24px;
  line-height: 24px;
}

.button.verybig {
  font-size: 40px;
  line-height: 48px;
}

.button.bold {
  color: #fff;
  text-shadow: -2px -2px #000, 2px -2px #000, -2px 2px #000, 2px 2px #000;
  padding: 0;
  font-family: Bungee Inline;
  font-weight: 300;
  transition: top .4s ease-out 2.5s;
  position: relative;
  top: 0;
}

.button.bold img {
  width: 101px;
}

.overlay {
  box-sizing: content-box;
  justify-content: center;
  justify-self: center;
  align-items: flex-start;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  position: absolute;
  overflow: hidden auto;
}

.overlay-content {
  box-sizing: border-box;
  flex-flow: column;
  align-items: center;
  width: 100%;
  min-width: 320px;
  max-width: 375px;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.default-infobox {
  color: #000;
  text-align: center;
  z-index: 1;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Bungee Inline;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  display: flex;
}

@media only screen and (width >= 425px) {
  .default-infobox {
    border-radius: 25px;
    width: 500px;
  }
}

.imgvirus {
  z-index: 0;
  background-position: center;
  background-size: cover;
  position: absolute;
}

a {
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
}

#creator {
  -webkit-user-select: all;
  user-select: all;
  z-index: 10000;
  width: 30vw;
  height: 30px;
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: all !important;
}

#creator textarea {
  pointer-events: all;
  user-select: unset;
  width: 100%;
  height: calc(100vh - 100px);
}

#creator .creatorform {
  display: none;
}

#creator button {
  pointer-events: all;
}

#creator.open {
  background-color: gray;
  width: 100vw;
  height: 100vh;
}

#creator.open .creatorform {
  display: block;
}

#background {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#background.closed .virus5 {
  top: 100vh;
}

#background .virus5 {
  z-index: 0;
  opacity: .2;
  background-position: top;
  background-size: cover;
  height: 100%;
  transition: top .8s ease-out;
  position: fixed;
  top: 300px;
  left: 0;
  right: 0;
}

.header, .bigheader, .medheader {
  color: #000;
  font-family: Bungee Inline;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
}

.header.green, .bigheader.green, .medheader.green {
  color: #54cc39;
}

.header.grey, .bigheader.grey, .medheader.grey {
  color: #aaa;
}

.header.breakall, .bigheader.breakall, .medheader.breakall {
  word-break: break-all;
}

.grey {
  color: #aaa;
}

.green {
  color: #54cc39;
}

.bigheader {
  font-size: 36px;
  line-height: 36px;
}

.medheader {
  font-size: 24px;
  line-height: 24px;
}

#btnReplay {
  pointer-events: all;
  position: fixed;
  bottom: 0;
  left: 0;
}

@keyframes animLand {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -737px 0;
  }
}

@keyframes animGrass {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: -737px 100%;
  }
}

@keyframes animBuilding {
  0% {
    left: 400px;
  }

  100% {
    left: -1000px;
  }
}

@keyframes animPipe {
  0% {
    left: 400px;
  }

  100% {
    left: -1000px;
  }
}

@keyframes animVirus {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.05);
  }
}

@keyframes animKillVirus {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animCeiling {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -587px 0;
  }
}

#gamecontainer {
  box-sizing: content-box;
  pointer-events: all;
  touch-action: none;
  flex-flow: column;
  justify-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.spacer {
  flex-grow: 1;
}

#gamescreen {
  box-sizing: border-box;
  background-color: #0000;
  background-size: 680px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 320px;
  max-width: 375px;
  height: 100%;
  min-height: 500px;
  display: flex;
  position: fixed;
  overflow-x: hidden;
}

#gamescreen.open {
  top: 0;
}

#ceiling {
  pointer-events: none;
  box-sizing: border-box;
  background-color: #0000;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

#grass {
  z-index: 4;
  touch-action: none;
  background-repeat: repeat-x;
  background-size: 737px;
  height: 1000px;
  animation: 10.9185s linear infinite animGrass;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#land {
  pointer-events: none;
  touch-action: none;
  z-index: 2;
  background-color: #0000;
  background-position: 0 0;
  background-repeat: repeat-x;
  width: 737px;
  height: 30px;
  animation: 5.45926s linear infinite animLand;
  bottom: 0;
}

#flyarea {
  z-index: 3;
  touch-action: none;
  flex-grow: 1;
  width: 500px;
  position: relative;
  overflow: hidden;
}

.building {
  pointer-events: none;
  z-index: 2;
  transform-origin: 0 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  animation: 51.8519s linear animBuilding;
  position: absolute;
  bottom: 50px;
  left: -1000px;
}

.building.molen {
  width: 100px;
  height: 100px;
}

.building.stadion {
  width: 100px;
  height: 35px;
}

#bigscore {
  pointer-events: none;
  z-index: 6;
  text-align: center;
  color: #fff;
  width: 100vw;
  font-size: 36px;
  position: fixed;
  top: 15px;
  left: 0;
}

#bigscore img {
  padding: 1px;
  display: inline-block;
}

.splash {
  width: 100%;
  height: 100%;
  position: absolute;
}

.splashcontent {
  z-index: 100;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
}

#medal {
  opacity: 0;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 114px;
  left: 32px;
}

.highscore {
  color: #54cc39;
}

.highscore img {
  padding-left: 2px;
}

.boundingbox {
  border: 1px solid red;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#player {
  box-sizing: border-box;
  z-index: 6;
  pointer-events: none;
  background-size: 100%;
  position: absolute;
  top: 200px;
  left: 10px;
}

#player.throw {
  animation: none;
}

#gamescreen.start {
  transition: top .2s ease-in;
}

#gamescreen.start #player {
  transition: left ease-out;
}

#gamescreen.start .splash {
  transition: transform 1s ease-out;
  transform: scale(0);
}

#player.snel {
  transition: unset;
}

#gamescreen {
  transition: top .45s ease-out;
}

#gamescreen #player {
  transition: left .5s ease-out;
}

#gamescreen #player.dead {
  transition: transform .7s ease-out, top .7s ease-in;
}

#gamescreen #splash .splashcontent {
  transition: transform .1s ease-out 1.75s;
  transform: scale(1);
}

.bullet {
  z-index: 7;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px;
  max-width: 35px;
  height: 35px;
  max-height: 35px;
  position: absolute;
  left: 400px;
}

.bullet .blocked {
  animation: none;
}

.virus {
  z-index: 5;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  width: 80px;
  max-width: 80px;
  height: 80px;
  max-height: 80px;
  position: absolute;
  left: -100px;
}

.virus.hit {
  opacity: 0;
}

.pipe {
  z-index: 5;
  pointer-events: none;
  height: 100%;
  animation: 10.3704s linear animPipe;
  position: absolute;
  left: -1000px;
}

.pipe_upper {
  background-position: bottom;
  background-repeat: no-repeat;
  width: 45px;
  position: absolute;
  top: 0;
}

.pipe_lower {
  background-position: top;
  background-repeat: no-repeat;
  width: 45px;
  position: absolute;
  bottom: 0;
}

#footer {
  position: absolute;
  bottom: 3px;
  left: 3px;
}

#footer a, #footer a:link, #footer a:visited, #footer a:hover, #footer a:active {
  color: #fff;
  padding: 2px;
  text-decoration: none;
  display: block;
}

.debugbox {
  box-sizing: border-box;
  z-index: 100;
  border: 3px solid red;
  position: absolute;
}

.debugbox.blue {
  border-color: #00f;
}

.debugbox.green {
  border-color: green;
}

.debugbox.purple {
  border-color: purple;
}

.currentscore {
  margin-bottom: 16px;
}

.currentscore img {
  padding-left: 2px;
}

#gamecontainer.deadflash #flyarea {
  animation: .1s linear animDeadFlash;
}

@keyframes animDeadFlash {
  0% {
    background-image: initial;
  }

  25% {
    background-image: unset;
  }

  75% {
    background-image: unset;
  }

  100% {
    background-image: initial;
  }
}

.debugPolygon {
  box-sizing: border-box;
  z-index: 3000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.popup.closed {
  max-height: 0;
  overflow: hidden;
}

.popup.open {
  opacity: 1;
  height: 100vh;
  bottom: 0;
}

.popup {
  opacity: 0;
  z-index: 10000;
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
  flex-flow: column;
  align-items: center;
  height: 100vh;
  transition: opacity .6s ease-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.popup-content {
  opacity: 1;
  color: #000;
  pointer-events: all;
  touch-action: pan-y;
  background-color: #fff;
  border-radius: 10px;
  flex-flow: column;
  max-width: 550px;
  min-height: 400px;
  max-height: calc(100% - 40px);
  margin-top: 20px;
  padding: 20px;
  transition: transform .6s ease-in;
  display: flex;
  position: fixed;
  overflow: hidden auto;
  transform: translateY(-120%);
}

.popup.open .popup-content {
  transform: translateY(0);
}

html.scrollblock {
  overflow-y: hidden;
}

#setScoreScreen .header, #setScoreScreen .bigheader {
  margin-top: 16px;
}

#setScoreValue {
  color: green;
}

#setScoreValue.header {
  margin-top: 0;
  font-size: 24px;
}

@media only screen and (height <= 450px) {
  #gamecontainer, #intro {
    transition: none;
    display: none;
  }

  #rotate {
    transition: none;
    display: flex;
  }
}

.invisible .button, .invisible a, .invisible .sharebar .sharebutton {
  pointer-events: none !important;
}

.pauseAnimation {
  animation-play-state: paused !important;
}
/*# sourceMappingURL=index.2340e8e8.css.map */
